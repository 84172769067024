<template>
  <div>
    <span
      >欢迎<font style="color:blue;bold">{{ user.name }}</font
      >使用冷弯联盟综合管理平台！</span
    >
    <br />
    <br />
    <!-- <div>
      <a href="/downloads/jsjz.apk" download="建术提醒.apk" style="text-decoration:underline;color:blue">建术提醒APP下载</a>
    </div> -->
    <div style="width: 70%;margin-left: 30px;margin-top: 30px;">
      <el-button class="filter-item" type="success" icon="el-icon-download" @click="downloadFile()">下载手机提醒APP</el-button>
    </div>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'

export default {
  data() {
    return {
      user: utils.getUserCookie()
    }
  },
  methods: {
    downloadFile() {
      const postUrl = '/downloadFiles/'
      let params = {
        filename: 'jsjz.apk'
      }
      this.$axios
        .post(postUrl, params, { responseType: 'blob' })
        .then(res => {
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', 'jsjz.apk') //指定下载后的文件名，防跳转
          document.body.appendChild(link)
          link.click()
        })
        .catch(function(error) {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
